import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
// @mui
import { Box, List, ListItemText, Collapse } from '@mui/material';
//
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { StyledNavItem, StyledNavItemIcon } from './styles';

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {
  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {data.map((item) => (
          <NavItem key={item.title} item={item} />
        ))}
      </List>
    </Box>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
};

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item }) {
  const { title, path, icon, subItems } = item;
  const [open, setOpen] = useState(false); // State to manage sub-items

  const handleToggle = () => {
    setOpen((prev) => !prev); // Toggle the open state
  };

  return (
    <>
      <StyledNavItem
        component={RouterLink}
        to={path}
        onClick={subItems ? handleToggle : undefined} // Only toggle if there are sub-items
        sx={{
          // Only apply selection styling if there are no sub-items
          '&.active': {
            color: 'text.primary',
            fontWeight: 'fontWeightBold',
            ...(subItems ? {} : { bgcolor: 'action.selected' }), // Disable background color if sub-items exist
          },
        }}
      >
        <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>
        <ListItemText disableTypography primary={title} sx={{ pr: 4 }} />
        <div style={{ paddingRight: 10 }}>{subItems && (open ? <ExpandLess /> : <ExpandMore />)}</div>
      </StyledNavItem>

      {subItems && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {subItems.map((subItem) => (
              <StyledNavItem
                key={subItem.title}
                component={RouterLink}
                to={subItem.path}
                sx={{
                  pl: 4,

                  '&.active': {
                    color: 'text.primary',
                    bgcolor: 'action.selected',
                    fontWeight: 'fontWeightBold',
                    pl: 4,
                  },
                }} // Indent sub-items
              >
                <ListItemText disableTypography primary={subItem.title} />
              </StyledNavItem>
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
}

// function NavItem({ item }) {
//   const { title, path, icon, info, subItems } = item;

//   return (
//     <StyledNavItem
//       component={RouterLink}
//       to={path}
//       sx={{
//         '&.active': {
//           color: 'text.primary',
//           bgcolor: 'action.selected',
//           fontWeight: 'fontWeightBold',
//         },
//       }}
//     >
//       <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>

//       <ListItemText disableTypography primary={title} />

//       {info && info}
//     </StyledNavItem>
//   );
// }
