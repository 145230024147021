import React, { useState, useEffect, useContext, useRef } from 'react';
import moment from 'moment';
import { ChatContext } from '../../context/ChatContext';

const Message = ({ message }) => {
  const { data } = useContext(ChatContext);
  const USER = localStorage.getItem('currentUser');
  const USER_PARSE = JSON.parse(USER);
  const UID = USER_PARSE.id;
  const key = UID;
  const ref = useRef();

  useEffect(() => {
    ref.current.scrollIntoView({ behavior: 'smooth' });
  }, [message]);

  const renderMessageContent = () => {
    if (message.image) {
      return (
        <img
          src={message.image}
          alt=""
          style={{ maxWidth: '60%', height: 'auto', alignSelf: 'flex-end', marginBottom: 20 }}
        />
      );
    }
    return <p style={{ alignSelf: message.user._id === UID ? 'flex-end' : 'flex-start' }}>{message.text}</p>;
  };

  return (
    <div ref={ref} className={`message ${message.user._id === UID && 'owner'}`}>
      <div className="messageContent">
        {renderMessageContent()}
        <span style={{ fontSize: 10, marginTop: -20, textAlign: 'right', marginRight: 10, marginBottom: 10 }}>
          {moment(message.createdAt).format('LLL')}
        </span>
      </div>
    </div>
  );
};

export default Message;
