import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { ChatContext } from '../../context/ChatContext';
import './style.css';
import { db } from '../../firebase';

const Chats = () => {
  const { dispatch } = useContext(ChatContext);
  const [rooms, setRooms] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    db.collection('chat_rooms')
      .orderBy('updated_at', 'desc')
      .onSnapshot((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setRooms(data);
      });
  }, []);

  const handleSelect = (room) => {
    dispatch({ type: 'CHANGE_USER', payload: room });
  };

  const filteredRooms = rooms.filter((room) =>
    room.participants[0].name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <div className="chatsContainer">
      <div className="searchBar">
        <input
          type="text"
          placeholder="Search existing chats by name"
          value={searchQuery}
          onChange={handleSearchInputChange}
          className="searchInput"
        />
      </div>
      <div className="roomsList">
        {filteredRooms.map((room, index) => (
          <button key={index} onClick={() => handleSelect(room)} className="roomButton">
            <div className="userChat">
              <img className="userChatImage" alt="" src={'https://img.freepik.com/free-icon/user_318-159711.jpg'} />
              <div className="userChatInfo">
                <span
                  style={{
                    textAlign: 'left',
                  }}
                >
                  {room.participants[0].name}
                </span>
                <p style={{ textAlign: 'left' }}>{room.last_message}</p>
              </div>
            </div>
          </button>
        ))}
      </div>
    </div>
  );
};

export default Chats;
