import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import BlogPage from './pages/BlogPage';

import UserPage from './pages/UserPage';
import PackagesPage from './pages/PackagesPage';
import PackagesItemsPage from './pages/PackagesItemsPage';
import BookingAvailability from './pages/bookingAvailability';
import SupportPage from './pages/SupportPage';
import UserBookings from './pages/Users/userBookings';
import ChatInbox from './pages/ChatInbox';
import AboutPage from './pages/AboutPage';
import PromotionsPage from './pages/PromotionsPage';
import MembershipsPage from './pages/MembershipsPage';
import FaqPage from './pages/FaqPage';
import ResidentialAreas from './pages/ResidentialAreas';
import DiscountCoupons from './pages/DiscountCoupons';
import WorkWithUsPage from './pages/WorkWithUsPage';
import ChangePassword from './pages/ChangePassword';
import Notifications from './pages/Notifications';
import PaymentMethods from './pages/PaymentMethods';
import TimeRestriction from './pages/TimeRestriction';

import CategoryPage from './pages/CategoryPage';
import BookingPage from './pages/BookingPage';
import Promotions from './pages/Promotions';
import ReportPage from './pages/ReportPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import ProtectedRoutes from './ProtectedRoutes';

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/',
      element: <LoginPage />,
      index: true,
    },
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        {
          element: <Navigate to="/dashboard/app" />,
          index: true,
        },
        {
          path: 'app',
          element: <DashboardAppPage />,
        },

        // Users Routes Start
        { path: 'user', element: <UserPage /> },
        { path: 'userBookings/:id', element: <UserBookings /> },
        // Users Routes End

        // Packages Route Start
        { path: 'packages', element: <PackagesPage /> },
        { path: 'packagesItems', element: <PackagesItemsPage /> },
        { path: 'bookingAvailability', element: <BookingAvailability /> },
        // Packages Route End

        // Suport Route Start
        { path: 'support', element: <SupportPage /> },
        { path: 'chatinbox', element: <ChatInbox /> },
        // Suport Route End

        // Support Messages

        { path: 'category', element: <CategoryPage /> },
        { path: 'bookings', element: <BookingPage /> },
        { path: 'promotions', element: <Promotions /> },
        { path: 'reports', element: <ReportPage /> },
        { path: 'products', element: <ProductsPage /> },
        { path: 'blog', element: <BlogPage /> },

        // App Content
        { path: 'about', element: <AboutPage /> },
        { path: 'promotionspage', element: <PromotionsPage /> },
        { path: 'memberships', element: <MembershipsPage /> },
        { path: 'faq', element: <FaqPage /> },
        { path: 'residentialareas', element: <ResidentialAreas /> },
        { path: 'discountcoupons', element: <DiscountCoupons /> },
        { path: 'workwithus', element: <WorkWithUsPage /> },
        { path: 'changepassword', element: <ChangePassword /> },
        { path: 'notifications', element: <Notifications /> },
        { path: 'paymentMethods', element: <PaymentMethods /> },
        { path: 'timerestriction', element: <TimeRestriction /> },
      ],
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
