// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: icon('ic_analytics'),
  },
  // {
  //   title: 'bookings',
  //   path: '/dashboard/bookings',
  //   icon: icon('ic_booking'),
  // },
  {
    title: 'users',
    path: '/dashboard/user',
    icon: icon('ic_user'),
  },
  {
    title: 'Services',
    path: '#',
    icon: icon('briefcase'),
    subItems: [
      {
        title: 'Services',
        path: '/dashboard/packages',
      },
      {
        title: 'Extras',
        path: '/dashboard/packagesItems',
      },
      {
        title: 'Booking Availability',
        path: '/dashboard/bookingAvailability',
      },
      {
        title: 'Time Restriction',
        path: '/dashboard/timerestriction',
      },
      {
        title: 'Service Areas',
        path: '/dashboard/residentialareas',
      },
      {
        title: 'Discount codes',
        path: '/dashboard/discountcoupons',
      },
      {
        title: 'Notifications',
        path: '/dashboard/notifications',
      },
    ],
  },

  // {
  //   title: 'support',
  //   path: '/dashboard/support',
  //   icon: icon('ic_kanban'),
  // },
  {
    title: 'Chat',
    path: '/dashboard/chatinbox',
    icon: icon('chat'),
  },

  {
    title: 'App Editor',
    path: '#',
    icon: icon('mobile'),
    subItems: [
      {
        title: 'About',
        path: '/dashboard/about',
      },
      {
        title: 'Promotions',
        path: '/dashboard/promotionspage',
      },
      {
        title: 'Memberships',
        path: '/dashboard/memberships',
      },
      {
        title: 'FAQ',
        path: '/dashboard/faq',
      },
      {
        title: 'Work With Us',
        path: '/dashboard/workwithus',
      },
      {
        title: 'Payment Methods',
        path: '/dashboard/paymentMethods',
      },
    ],
  },

  {
    title: 'Settings',
    path: '#',
    icon: icon('cog'),
    subItems: [
      {
        title: 'Change Password',
        path: '/dashboard/changepassword',
      },
    ],
  },
];

export default navConfig;
