import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import Message from './Message';
import { ChatContext } from '../../context/ChatContext';
import './style.css';
import { db } from '../../firebase';

const Messages = () => {
  const { data } = useContext(ChatContext);
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    getMessages();
  }, [data.chatId]);

  const getMessages = async () => {
    const messageRef = await db
      .collection('chat_rooms')
      .doc(`${data.chatId}`)
      .collection('messages')
      .orderBy('created_at', 'asc');

    messageRef.onSnapshot((querySnapshot) => {
      const allmsg = querySnapshot.docs.map((docSnap) => {
        const data = docSnap.data();

        const time = {
          seconds: data.created_at.seconds,
          nanoseconds: data.created_at.nanoseconds,
        };

        const fireBaseTime = new Date(time.seconds * 1000 + time.nanoseconds / 1000000);
        const date1 = fireBaseTime.toDateString();
        const atTime = fireBaseTime.toLocaleTimeString();
        const finaldate = `${date1} ${atTime}`;
        const printDate = moment(finaldate).format('YYYY-MM-DD HH:mm:ss');

        return {
          text: data.message,
          image: data.image,
          createdAt: printDate,
          user: {
            _id: data.sender_id,
            name: data.name,
          },
        };
      });

      setMessages(allmsg);
    });
  };

  return (
    <div className="messages">
      {messages.map((m) => (
        <Message message={m} />
      ))}
    </div>
  );
};

export default Messages;
