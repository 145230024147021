import React from 'react';
import Sidebar from './Chat/Sidebar';
import Chat from './Chat/Chat';

const ChatInbox = () => {
  return (
    <div
      style={{
        backgroundColor: '#ffffff',
        height: '85vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: '#ffffff',
          borderRadius: 10,
          width: '95%',
          height: '90%',
          display: 'flex',
          overflow: 'hidden',
        }}
      >
        <Sidebar />
        <Chat />
      </div>
    </div>
  );
};

export default ChatInbox;
