import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { sentenceCase } from 'change-case';
// @mui
import {
  Avatar,
  Grid,
  Card,
  Table,
  Stack,
  Paper,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  OutlinedInput,
  InputAdornment,
} from '@mui/material';
import { Oval } from 'react-loader-spinner';
import { useParams } from 'react-router-dom';
import { styled, alpha } from '@mui/material/styles';
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import { BaseURL, BaseURLImage } from '../common/Base_Url';

import { AppWidgetSummary } from '../sections/@dashboard/app';

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    width: 320,
    boxShadow: theme.customShadows.z8,
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

export default function BookingPage() {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);

  const [completeBookings, setCompleteBookings] = useState(0);
  const [pendingBookings, setPendingBookings] = useState(0);
  const [inProcessBookings, setInProcessBookings] = useState(0);
  const [cancelBookings, setCancelBookings] = useState(0);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [query, setQuery] = useState('');

  const [TABLE_DATA, setAllBookings] = useState([]);

  useEffect(() => {
    getAlBookings();
  }, []);

  const getAlBookings = () => {
    setLoading(true);

    const TOKEN = localStorage.getItem('currentUserAccessToken');

    fetch(`${BaseURL}getAllBookings`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
        Authorization: `Bearer ${TOKEN}`,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        const PENDING_BOOKINGS = responseJson.bookings.filter((item) => item.status === 'pending');
        const CONFIRM_BOOKINGS = responseJson.bookings.filter((item) => item.status === 'confirm');
        const CANCEL_BOOKINGS = responseJson.bookings.filter((item) => item.status === 'cancel');

        setCompleteBookings(CONFIRM_BOOKINGS.length);
        setPendingBookings(PENDING_BOOKINGS.length);
        setCancelBookings(CANCEL_BOOKINGS.length);

        const DATA = [];
        responseJson.bookings.forEach((element) => {
          DATA.push({
            id: element.id,
            userId: element.user_id,
            bookingDate: element.bookingDate,
            bookingTime: element.bookingTime,
            bookingPrice: element.bookingPrice,
            bookingAddress: element.bookingAddress,
            vehicleId: element.vehicleId,
            vehicleYear: element.vehicleYear,
            vehicleMake: element.vehicleMake,
            vehicleType: element.vehicleType,
            vehicleModel: element.vehicleModel,
            vehicleImage: element.vehicleImage,
            packageID: element.packageID,
            packageName: element.packageName,
            packageTime: element.packageTime,
            packageItems: element.packageItems,
            status: element.status,
            created_at: element.created_at,
          });
        });

        setLoading(false);

        setAllBookings(DATA);
      })
      .catch((error) => {
        alert(JSON.stringify(error));
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const search = (data) => {
    return data.filter((item) => item.status.toLowerCase().includes(query));
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - search(TABLE_DATA).length) : 0;
  const isNotFound = !TABLE_DATA.length && !!TABLE_DATA;

  return (
    <>
      <Helmet>
        <title> Bookings | Nova Auto Spa </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Bookings
          </Typography>
          {loading === true ? (
            <Oval
              height={30}
              width={30}
              color="#0F70F5"
              wrapperStyle={{}}
              wrapperClass=""
              visible={'true'}
              ariaLabel="oval-loading"
              secondaryColor="#2e3a59"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          ) : null}
        </Stack>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary
              title="Pending Bookings"
              total={pendingBookings}
              color="info"
              icon={'ant-design:calendar'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary
              title="Completed Bookings"
              total={completeBookings}
              color="info"
              icon={'ant-design:calendar'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary
              title="Cancelled Bookings"
              total={cancelBookings}
              color="info"
              icon={'ant-design:calendar'}
            />
          </Grid>
        </Grid>

        <div style={{ marginTop: 20 }}>
          <Card>
            <div style={{ marginTop: 20, marginBottom: 20, marginLeft: 20 }}>
              <StyledSearch
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                placeholder="Search Booking..."
                startAdornment={
                  <InputAdornment position="start">
                    <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                  </InputAdornment>
                }
              />
            </div>

            <Scrollbar>
              <TableContainer sx={{ minWidth: 720 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ minWidth: 240 }}>Booking ID</TableCell>
                      <TableCell sx={{ minWidth: 240 }}>Booking Date</TableCell>
                      <TableCell sx={{ minWidth: 240 }}>Booking Time</TableCell>
                      <TableCell sx={{ minWidth: 240 }}>Booking Price</TableCell>
                      <TableCell sx={{ minWidth: 240 }}>Booking Address</TableCell>
                      <TableCell sx={{ minWidth: 240 }}>Vehicle Image</TableCell>
                      <TableCell sx={{ minWidth: 240 }}>Vehicle Make</TableCell>
                      <TableCell sx={{ minWidth: 240 }}>Vehicle Year</TableCell>
                      <TableCell sx={{ minWidth: 240 }}>Vehicle Type</TableCell>
                      <TableCell sx={{ minWidth: 240 }}>Vehicle Model</TableCell>
                      <TableCell sx={{ minWidth: 240 }}>Package Name</TableCell>
                      <TableCell sx={{ minWidth: 240 }}>Package Time</TableCell>
                      <TableCell sx={{ minWidth: 240 }}>Package Items</TableCell>
                      <TableCell sx={{ minWidth: 120 }}>Status</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {search(TABLE_DATA)
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row) => {
                        const {
                          id,
                          userId,
                          bookingDate,
                          bookingTime,
                          bookingPrice,
                          bookingAddress,
                          vehicleId,
                          vehicleMake,
                          vehicleYear,
                          vehicleType,
                          vehicleModel,
                          vehicleImage,
                          packageID,
                          packageName,
                          packageTime,
                          packageItems,
                          status,
                        } = row;

                        return (
                          <TableRow key={id}>
                            <TableCell>{id}</TableCell>
                            <TableCell>{bookingDate}</TableCell>
                            <TableCell>{bookingTime}</TableCell>
                            <TableCell>${bookingPrice}</TableCell>
                            <TableCell>{bookingAddress}</TableCell>
                            <TableCell>
                              <Avatar
                                style={{ borderRadius: 0, width: 200, height: 200 }}
                                alt={vehicleImage}
                                src={BaseURLImage + vehicleImage}
                              />
                            </TableCell>
                            <TableCell>{vehicleMake}</TableCell>
                            <TableCell>{vehicleYear}</TableCell>
                            <TableCell>{vehicleType}</TableCell>
                            <TableCell>{vehicleModel}</TableCell>
                            <TableCell>{packageName}</TableCell>
                            <TableCell>{packageTime}</TableCell>
                            <TableCell>{packageItems}</TableCell>
                            <TableCell align="left">
                              <Label
                                color={
                                  status === 'cancel'
                                    ? 'error'
                                    : status === 'pending'
                                    ? 'secondary'
                                    : status === 'confirm'
                                    ? 'success'
                                    : status === 'confirm'
                                    ? 'default'
                                    : ''
                                }
                              >
                                {sentenceCase(status)}
                              </Label>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                  {isNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              Not found
                            </Typography>

                            <Typography variant="body2">
                              No results found for &nbsp;
                              <strong>&quot;{TABLE_DATA}&quot;</strong>.
                              <br /> Try checking for typos or using complete words.
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={search(TABLE_DATA).length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </div>
      </Container>
    </>
  );
}
